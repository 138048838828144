import React, { useState ,useEffect} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import swal from 'sweetalert';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { hashing } from '../../../common/hashing';
import { getCurrentDate } from '../../../common/getCurrentDate';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';
import { numberWithUnderbar } from '../../../common/numberWithUnderbar';
import { numberWithCommas } from '../../../common/numberWithCommas';
import { deleteSpecialChar } from '../../../common/deleteSpecialChar';
import { isMobile } from 'react-device-detect';

  const ManualPay = () => {
   const navigate = useNavigate();
   const [cookies, setCookie, removeCookie] = useCookies();
   const [mid,setMid] = useState("");
   const [mkey,setMkey] = useState();
   const [rowShow , setRowShow] = useState(false);


   const [selectquotaMonList , setSelectquotaMonList] = React.useState(
     [{value : '00' , lavel : '일시불'}]
      ); 

      function quotaMonList(quotaMon){
         var arr =[];
          for(var i=2;i<=quotaMon;i++){
            if(i >= 10){
               arr.push({value : i+'' , lavel : i+'개월'});   
            }else{
               arr.push({value : '0'+i , lavel : i+'개월'});
            }
          }
          setSelectquotaMonList(selectquotaMonList.concat(arr))
       }
    
    const [selectquotaMon,setSelectquotaMon] = useState('00');

   //수기결제 권한 조회 및 mkey,mid,할부개월수 추출
   function manualPayInfo(){
      Swal.showLoading();
      axios
      .post(
         process.env.REACT_APP_API_HOST + "/mms/manualPayInfo",
         {
            token : cookies.token,
            usrTypeId : cookies.id,
            usrType:cookies.type,
            userId : cookies.userid
         },
         {
            headers: {
               'Content-Type': 'application/json',
               'X-Requested-With': 'XMLHttpRequest',
            },
         }
      )
      .then(function (response) {
         if (response.data.res_code != '0000') {
            swal({
              text: response.data.res_msg,
              button: {
                text: '확인',
              },
            });
            navigate('/app/main', { replace: false });
            if (response.data.res_code == '1004') {
              swal({
                text: response.data.res_msg,
                button: {
                  text: '확인',
                },
              });
              navigate('/login', { replace: false });
            }
            Swal.close();
          } else {
            setMid(response.data.data.MID);
            setMkey(response.data.data.MKEY);
            quotaMonList(response.data.data.LMT_INSTMN)
            if(response.data.data.KEYIN_CL_CD =='01'){
               setRowShow(true);
            }
            Swal.close();
          }
        }).catch(function (error) {
          Swal.close();
          swal({
            text: '미지정 오류',
            button: {
              text: '확인',
            },
          });
      });
      }

      //수기결제 유효성 검사
      function manualPayCheckedValue(value){
         let check = /^[0-9]+$/;
         if (!value.goodsNm) {
            swal({
               text: '상품명을 확인해주세요',
               button: {
                  text: '확인',
               },
            });
            return false;
         }
         if (!value.goodsAmt) {
            swal({
               text: '승인금액을 확인해주세요',
               button: {
                  text: '확인',
               },
            });
            return false;
         }
         if (!value.cardNo) {
            swal({
               text: '카드번호를 확인해주세요',
               button: {
                  text: '확인',
               },
            });
            return false;
         }
         if (!value.expireYm) {
            swal({
               text: '유효기간을 확인해주세요',
               button: {
                  text: '확인',
               },
            });
            return false;
         }
         if (!value.ordNm) {
            swal({
               text: '구매자명을 확인해주세요',
               button: {
                  text: '확인',
               },
            });
            return false;
         }
         if (!value.ordHp || value.ordHp.length < 9 || !check.test(value.ordHp)) {
            swal({
               text: '휴대폰번호 확인해주세요',
               button: {
                  text: '확인',
               },
            });
            return false;
         }

         if(!rowShow){
            if (!value.certNo) {
               swal({
                  text: '생년월일,사업자번호를 확인해주세요',
                  button: {
                     text: '확인',
                  },
               });
               return false;
            }

            if (!value.certPw) {
               swal({
                  text: '비밀번호를 확인해주세요',
                  button: {
                     text: '확인',
                  },
               });
               return false;
            }
         }
         requestManualpay(value);
      }

      function requestManualpay(value){
         Swal.fire({
            text: '결제 요청하시겠습니까?',
            showCancelButton: true,
            confirmButtonText: '예',
            cancelButtonText: '아니요'
          }).then((result) => {
            if (result.isConfirmed) {
               manualpay(value);
            }
          })
        }
      

     //수기결제 요청

     function manualpay(value){
      Swal.showLoading();
      let data;
        if(rowShow){
          data ={
            mid:mid,
            goodsAmt:deleteSpecialChar(value.goodsAmt),
            cardNo:deleteSpecialChar(value.cardNo),
            expireYymm:value.expireYm,
            quotaMon:selectquotaMon,
            buyer_nm:value.ordNm,
            ordNm:value.ordNm,
            goodsNm:value.goodsNm,
            mkey:mkey,
            hashKey:hashing(mid,value.goodsAmt),
            ordHp:deleteSpecialChar(value.ordHp),
            ordNo:mid+getCurrentDate()+"000mms",
           }
        }else{
         data ={
            mid:mid,
            goodsAmt:deleteSpecialChar(value.goodsAmt),
            cardNo:deleteSpecialChar(value.cardNo),
            expireYymm:value.expireYm,
            quotaMon:selectquotaMon,
            buyer_nm:value.ordNm,
            ordNm:value.ordNm,
            goodsNm:value.goodsNm,
            mkey:mkey,
            hashKey:hashing(mid,value.goodsAmt),
            certNo:value.certNo,
            certPw:value.certPw,
            ordHp:deleteSpecialChar(value.ordHp),
            ordNo:mid+getCurrentDate()+"000mms",
          }
        }
      axios
      .post(
         "https://pgapi.paysis.co.kr/api/manualpay",data,
      {
         headers: {
            'Content-Type': 'application/json',
         },
      }
   )
   .then(function (response) {
         if (response.data.res_code != '0000') {
            swal({
            text: response.data.res_msg,
            button: {
               text: '확인',
            },
            });
            if (response.data.res_code == '1004') {
            swal({
               text: response.data.res_msg,
               button: {
                  text: '확인',
               },
            });
            navigate('/login', { replace: false });
            }
            Swal.close();
         } else {
            swal({
               text: response.data.res_msg,
               button: {
                 text: '확인',
               },
             }).then(function (){
                if(isMobile){
                  navigate('/app/manualpaylist', { replace: false });
                }else{
                  navigate('/app/trans', { replace: false });
                }
                Swal.close();
             });
         }
      }).catch(function (error) {
         Swal.close();
         swal({
            text: '미지정 오류',
            button: {
            text: '확인',
            },
         });
      });
   
     }


     useEffect(() => {
      manualPayInfo();
    },[]);
  
    return (
      <Formik
      initialValues={{
        goodsNm: '',
        goodsAmt: '',
        cardNo: '',
        expireYm: '',
        ordNm: '',
        ordHp: '',
        certNo: '',
        certPw: '',
      }}
      onSubmit={(value) => {
         manualPayCheckedValue(value);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form  onSubmit={handleSubmit}>
          <Card>
          <CardHeader title="신용카드 수기결제" />
          <Divider />
          <Table aria-label="a dense table">
              <TableBody>
                  <TableRow>
                    <TableCell>
                    관리계정
                    </TableCell>
                    <TableCell>
                    <TextField   name="mid" size="small" variant="outlined" value={mid} disabled/>
                    </TableCell>
                 </TableRow>
                 <TableRow>
                    <TableCell>
                    상품명
                    </TableCell>
                    <TableCell>
                    <TextField   name="goodsNm"  value={values.goodsNm} onBlur={handleBlur} onChange={handleChange} size="small" variant="outlined" />
                    </TableCell>
                 </TableRow>
                 <TableRow>
                    <TableCell>
                    승인금액
                    </TableCell>
                    <TableCell>
                    <TextField name="goodsAmt"  value={values.goodsAmt} onBlur={handleBlur} onChange={handleChange}  size="small" variant="outlined" />
                    </TableCell>
                 </TableRow>
                 <TableRow>
                    <TableCell>
                    할부
                    </TableCell>
                    <TableCell>
             <Select size='small'
                variant="outlined"
                defaultValue={'00'}
                value={selectquotaMonList.value}
                onChange={(event) =>{
                  setSelectquotaMon(event.target.value);
                  }}
              >
                 {selectquotaMonList?.map(option => {
              return (
              <MenuItem  name='quotaMon' key={option.value} value={option.value}>
                  {option.lavel}
              </MenuItem>
                );
             })}
            </Select>
                    {/* <TextField id="quotaMon" value={values.quotaMon} onBlur={handleBlur} onChange={handleChange} size="small" variant="outlined" /> */}
                    </TableCell>
                 </TableRow>
                 <TableRow>
                    <TableCell>
                    카드번호
                    </TableCell>
                    <TableCell>
                    <TextField id="cardNo" value={numberWithUnderbar(values.cardNo)} onBlur={handleBlur} onChange={handleChange} size="small" variant="outlined" />
                    </TableCell>
                 </TableRow>
                 <TableRow>
                    <TableCell>
                    유효기간<br></br>(년/월)
                    </TableCell>
                    <TableCell>
                    <TextField id="expireYm"  type="password" value={values.expireYm} onBlur={handleBlur} onChange={handleChange} size="small" variant="outlined" />
                    </TableCell>
                 </TableRow>
                 <TableRow style={rowShow  ? {display:'none'} : {display: 'default'}}>
                    <TableCell>
                    (개인)생년월일<br></br>(법인)사업자번호
                    </TableCell>
                    <TableCell>
                    <TextField id="certNo"
                               type="text"
                               value={values.certNo}
                               onBlur={handleBlur}
                               onChange={handleChange}
                               size="small"
                               placeholder="생년월일6자리YYMMDD"
                               variant="outlined" />
                    </TableCell>
                 </TableRow>
                 <TableRow style={rowShow  ? {display:'none'} : {display: 'default'}}> 
                    <TableCell>
                    비밀번호
                    </TableCell>
                    <TableCell>
                    <TextField id="certPw" type="password"  value={values.certPw} onBlur={handleBlur} onChange={handleChange} placeholder="카드 비밀번호 앞 2자리" inputProps={{ maxLength: 2}} size="small" variant="outlined" />
                    </TableCell>
                 </TableRow>
                 <TableRow>
                    <TableCell>
                    구매자명
                    </TableCell>
                    <TableCell>
                    <TextField id="ordNm" value={values.ordNm} onBlur={handleBlur} onChange={handleChange} size="small" variant="outlined" />
                    </TableCell>
                 </TableRow>
                 <TableRow>
                    <TableCell>
                    휴대폰번호
                    </TableCell>
                    <TableCell>
                    <TextField id="ordHp"  value={values.ordHp} onBlur={handleBlur} onChange={handleChange} size="small" variant="outlined" />
                    </TableCell>
                 </TableRow>
              </TableBody>
           </Table>
           <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    결제하기
                  </Button>
          </Card>
        </form>
      )}
        </Formik>
      );
  };
  
  
  export default ManualPay;