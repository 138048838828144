import { numberWithCommas } from "../numberWithCommas";

export const bankReportExcel = (data,usrType) =>{

  var headers;
  var headerName;
  var newRowData;
  const xlsx = require( "xlsx" );

  if(usrType == 1){
    headers = [
      ,"TR_DT"                               
        ,"CO_NM"                  
        ,'CAT_ID'             
        ,"MID"
        ,"GID"
        ,"APP_AMT"                               
        ,"APP_CNT"                               
        ,"CC_AMT"                               
        ,"CC_CNT"                               
        ,"FEE"                               
        ,"VAT"                               
        ,"PRESTMT_FEE"                               
        ,"PRESTMT_AMT"                               
        ,"DPST_AMT"                               
    ];

    headerName = {
      'TR_DT': '거래일자',
      'CO_NM': '상호명',
      'MID': 'MID',
      'GID': 'GID',
      'CAT_ID' : '단말기번호',
      'APP_AMT': '승인금액',
      'APP_CNT': '승인건수',
      'CC_AMT': '취소금액',
      'CC_CNT': '취소건수',
      'FEE': '수수료',
      'VAT': 'VAT',
      'PRESTMT_FEE': '선지급 수수료',
      'PRESTMT_AMT': '선지급 금액',
      'DPST_AMT': '입금액'
    }

  newRowData = data.map(rec => {
    return {
      'TR_DT': rec.TR_DT,
      'CO_NM': rec.CO_NM,
      'MID': rec.MID,
      'GID': rec.GID,
      'CAT_ID' : rec.CAT_ID,
      'APP_AMT': rec.APP_AMT,
      'APP_CNT': rec.APP_CNT,
      'CC_AMT': rec.CC_AMT,
      'CC_CNT': rec.CC_CNT,
      'FEE': rec.FEE,
      'VAT': rec.VAT,
      'PRESTMT_FEE': rec.PRESTMT_FEE,
      'PRESTMT_AMT': rec.PRESTMT_AMT,
      'DPST_AMT': rec.DPST_AMT
    }
  })
  }else if(usrType == 3){
    headers = [
      ,"TR_DT"
        ,"CO_NM"                               
        ,"MID"
        ,"GID"
        ,"CAT_ID"                               
        ,"L3_VID"          
        ,"VGRP_NM3"                     
        ,"APP_AMT"                               
        ,"APP_CNT"                               
        ,"CC_AMT"                               
        ,"CC_CNT"                               
        ,"FEE"                               
        ,"VAT"                               
        ,"PRESTMT_FEE"                               
        ,"PRESTMT_AMT"                               
        ,"DPST_AMT"                               
    ];

    headerName = {
      'TR_DT': '거래일자',
      'CO_NM': '상호명',
      'MID': 'MID',
      'GID': 'GID',
      'CAT_ID' : '단말기번호',
      'L3_VID': 'L3_VID',
      'VGRP_NM3': 'VGRP_NM3',
      'APP_AMT': '승인금액',
      'APP_CNT': '승인건수',
      'CC_AMT': '취소금액',
      'CC_CNT': '취소건수',
      'FEE': '수수료',
      'VAT': 'VAT',
      'PRESTMT_FEE': '선지급 수수료',
      'PRESTMT_AMT': '선지급 금액',
      'DPST_AMT': '입금액'
    }

  newRowData = data.map(rec => {
    return {
      'TR_DT': rec.TR_DT,
      'CO_NM': rec.CO_NM,
      'MID': rec.MID,
      'GID': rec.GID,
      'CAT_ID' : rec.CAT_ID,
      'L3_VID': rec.L3_VID,
      'VGRP_NM3': rec.VGRP_NM3,
      'APP_AMT': rec.APP_AMT,
      'APP_CNT': rec.APP_CNT,
      'CC_AMT': rec.CC_AMT,
      'CC_CNT': rec.CC_CNT,
      'FEE': rec.FEE,
      'VAT': rec.VAT,
      'PRESTMT_FEE': rec.PRESTMT_FEE,
      'PRESTMT_AMT': rec.PRESTMT_AMT,
      'DPST_AMT': rec.DPST_AMT
    }
  })
  }else if(usrType == 4){
    headers = [
      ,"TR_DT"
        ,"CO_NM"                               
        ,"MID"
        ,"GID"          
        ,"CAT_ID"                     
        ,"L2_VID"       
        ,"VGRP_NM2"                        
        ,"L3_VID"  
        ,"VGRP_NM3"                             
        ,"APP_AMT"                               
        ,"APP_CNT"                               
        ,"CC_AMT"                               
        ,"CC_CNT"                               
        ,"FEE"                               
        ,"VAT"                               
        ,"PRESTMT_FEE"                               
        ,"PRESTMT_AMT"                               
        ,"DPST_AMT"                               
    ];

    headerName = {
      'TR_DT': '거래일자',
      'CO_NM': '상호명',
      'MID': 'MID',
      'GID': 'GID',
      'CAT_ID' : '단말기번호',
      'L2_VID': 'L2_VID',
      'VGRP_NM2' : '대리점명',
      'L3_VID': 'L3_VID',
      'VGRP_NM3' : '딜러명',
      'APP_AMT': '승인금액',
      'APP_CNT': '승인건수',
      'CC_AMT': '취소금액',
      'CC_CNT': '취소건수',
      'FEE': '수수료',
      'VAT': 'VAT',
      'PRESTMT_FEE': '선지급 수수료',
      'PRESTMT_AMT': '선지급 금액',
      'DPST_AMT': '입금액'
    }

  newRowData = data.map(rec => {
    return {
      'TR_DT': rec.TR_DT,
      'CO_NM': rec.CO_NM,
      'MID': rec.MID,
      'GID': rec.GID,
      'CAT_ID' : rec.CAT_ID,
      'VID': rec.VID,
      'L2_VID': rec.L2_VID,
      'VGRP_NM2':rec.VGRP_NM2,
      'L3_VID': rec.L3_VID,
      'VGRP_NM3':rec.VGRP_NM3,
      'APP_AMT': rec.APP_AMT,
      'APP_CNT': rec.APP_CNT,
      'CC_AMT': rec.CC_AMT,
      'CC_CNT': rec.CC_CNT,
      'FEE': rec.FEE,
      'VAT': rec.VAT,
      'PRESTMT_FEE': rec.PRESTMT_FEE,
      'PRESTMT_AMT': rec.PRESTMT_AMT,
      'DPST_AMT': rec.DPST_AMT
    }
  })
  }else{
    headers = [
      ,"TR_DT"
        ,"CO_NM"                               
        ,"MID"
        ,"GID"     
        ,"CAT_ID"                          
        ,"VID"    
        ,"VGRP_NM"                           
        ,"L2_VID"
        ,"VGRP_NM2"                               
        ,"L3_VID" 
        ,"VGRP_NM3"                              
        ,"APP_AMT"                               
        ,"APP_CNT"                               
        ,"CC_AMT"                               
        ,"CC_CNT"                               
        ,"FEE"                               
        ,"VAT"                               
        ,"PRESTMT_FEE"                               
        ,"PRESTMT_AMT"                               
        ,"DPST_AMT"                               
    ];

    headerName = {
      'TR_DT': '거래일자',
      'CO_NM': '상호명',
      'MID': 'MID',
      'GID': 'GID',
      'CAT_ID': '단말기번호',
      'VID': 'L1_VID',
      'VGRP_NM':'총판명',
      'L2_VID': 'L2_VID',
      'VGRP_NM2':'대리점명',
      'L3_VID': 'L3_VID',
      'VGRP_NM3':'딜러명',
      'APP_AMT': '승인금액',
      'APP_CNT': '승인건수',
      'CC_AMT': '취소금액',
      'CC_CNT': '취소건수',
      'FEE': '수수료',
      'VAT': 'VAT',
      'PRESTMT_FEE': '선지급 수수료',
      'PRESTMT_AMT': '선지급 금액',
      'DPST_AMT': '입금액'
    }

  newRowData = data.map(rec => {
    return {
      'TR_DT': rec.TR_DT,
      'CO_NM': rec.CO_NM,
      'MID': rec.MID,
      'GID': rec.GID,
      'CAT_ID': rec.CAT_ID,
      'VID': rec.VID,
      'VGRP_NM':rec.VGRP_NM,
      'L2_VID': rec.L2_VID,
      'VGRP_NM2':rec.VGRP_NM2,
      'L3_VID': rec.L3_VID,
      'VGRP_NM3':rec.VGRP_NM3,
      'APP_AMT': rec.APP_AMT,
      'APP_CNT': rec.APP_CNT,
      'CC_AMT': rec.CC_AMT,
      'CC_CNT': rec.CC_CNT,
      'FEE': rec.FEE,
      'VAT': rec.VAT,
      'PRESTMT_FEE': rec.PRESTMT_FEE,
      'PRESTMT_AMT': rec.PRESTMT_AMT,
      'DPST_AMT': rec.DPST_AMT
    }
  })
  }
    newRowData.unshift(headerName);

    const book = xlsx.utils.book_new();
    const bankReportList = xlsx.utils.json_to_sheet( newRowData

, { header :headers , skipHeader : true } );

xlsx.utils.book_append_sheet( book,bankReportList);

xlsx.writeFile( book, '페이시스_입금보고서.xlsx');
}
