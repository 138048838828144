import React, {useState, useEffect, useContext} from 'react';
import {
    Card,
    List,
    ListItem,
    TablePagination,
    Divider, CardHeader,
} from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { FormControl } from '@material-ui/core';
import { Button } from '@mui/material';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ko from 'date-fns/locale/ko';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import {MyContext} from "../../../App";
import {numberWithCommasGrid} from "../../../common/grid/numberWithCommasGrid";
import {dateYYYYMMDDGrid} from "../../../common/grid/dateYYYYMMDDGrid";
import {dateTimeGrid} from "../../../common/grid/dateTimeGrid";
import {dateRange} from "../../../common/dateRange";
import {deleteSpecialChar} from "../../../common/deleteSpecialChar";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import GroupVidVidGidMidInfo from "../../../common/GroupVidVidGidMidInfo";
import {preStmtReportExcel} from "../../../common/excel/preStmtReportExcel";

const getFormatDate = (date) => {
    var year = date.getFullYear();
    var month = 1 + date.getMonth();
    month = month >= 10 ? month : '0' + month;
    var day = date.getDate();
    day = day >= 10 ? day : '0' + day;
    return year + '/' + month + '/' + day;
};

const PreStmtMain = () => {
    const { selectInfoList } = useContext(MyContext);
    const [cookies] = useCookies();
    const [rowData, setRowData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]); // 선택된 행 저장
    const navigate = useNavigate();

    const currentDate = new Date();
    const [startDate, setStartDate] = useState(getFormatDate(currentDate));
    const [endDate, setEndDate] = useState(getFormatDate(currentDate));
    const dateList = [
        { value: 'prestmtdt', label: '처리일자' },
    ];
    const [dateType, setDateType] = useState('prestmtdt');
    const [selectType, setSelectType] = useState('select');
    const selectTypeList = [
        { value: 'select', label: '선택하세요' },
        { value: 'mid', label: 'MID' },
        { value: 'vid', label: 'VID' },
        { value: 'gid', label: 'GID' },
        { value: 'catid', label: '단말기번호' },
    ];


    const dateButton = (e) => {
        const date = new Date();
        const dates = new Date();
        if (e.target.value == '0') {
            date.setDate(date.getDate() - 1);
            setStartDate(getFormatDate(date));
            setEndDate(getFormatDate(date));
        } else if (e.target.value == '1') {
            date.setDate(date.getDate());
            setStartDate(getFormatDate(date));
            setEndDate(getFormatDate(date));
        } else if (e.target.value == '2') {
            date.setDate(date.getDate() - 7);
            dates.setDate(dates.getDate());
            setStartDate(getFormatDate(date));
            setEndDate(getFormatDate(dates));
        } else if (e.target.value == '3') {
            date.setDate(date.getDate() - 31);
            dates.setDate(dates.getDate());
            setStartDate(getFormatDate(date));
            setEndDate(getFormatDate(dates));
        }
    }

    function excelDownLoad(){
        preStmtReportExcel(rowData,cookies.type);
    }
    const preStmtList = () => {
        let limitDay = dateRange(startDate, endDate);
        if (limitDay > 100) {
            swal({
                text: '시작일자와(과) 종료일자의 간격은 100일 이내여야 합니다.',
                button: {
                    text: '확인',
                },
            });
            return false;
        }

        Swal.showLoading(); // 로딩 시작
        axios
            .post(process.env.REACT_APP_API_HOST + "/mms/preStmtList", {
                token: cookies.token,
                userId: cookies.userid,
                usrTypeId: cookies.id,
                usrType: cookies.type,
                l1_vid: selectInfoList.l1Vid,
                l2_vid: selectInfoList.l2Vid,
                l3_vid: selectInfoList.l3Vid,
                mid: selectInfoList.mid,
                startDate: deleteSpecialChar(startDate),
                endDate: deleteSpecialChar(endDate),
                dateType: dateType,
                selectType: selectType,
                textSelectType: document.getElementById('textSelectType').value
            })
            .then(function (response) {
                if (response.data.res_code !== '0000') {
                    swal({
                        text: response.data.res_msg,
                        button: {
                            text: '확인',
                        },
                    });
                    if (response.data.res_code === '1004') {
                        navigate('/login', { replace: false });
                    }
                } else {
                    setRowData(response.data.data); // 데이터 세팅
                }
            })
            .catch(function (error) {
                // 오류가 발생했을 때 오류 처리
                swal({
                    text: '미지정 오류가 발생했습니다. 다시 시도해 주세요.',
                    button: {
                        text: '확인',
                    },
                });
            })
            .finally(() => {
                Swal.close(); // 요청이 끝나면 로딩 종료
            });
    };

    return (
        <>
            <div style={{float: 'right'}}>
                <Button size="small" variant="contained" color="primary" onClick={preStmtList}>
                    조회
                </Button>
                <Button  size="small" variant="contained" color="success" onClick={excelDownLoad}>
                    EXCEL
                </Button>
            </div>
            <br/>
            <br/>
            <GroupVidVidGidMidInfo />
            <br/>
            <Card>
                <FormControl sx={{m: 1, width: 250}}>
                    <Select
                        size="small"
                        defaultValue={'prestmtdt'}
                        value={dateType}
                        inputProps={{
                            id: 'selectDateType',
                        }}
                        onChange={(event) => {
                            setDateType(event.target.value);
                        }}
                        style={{marginRight: '15px'}}
                    >
                        {dateList.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
                    <DatePicker
                        value={startDate}
                        inputFormat={"yyyy/MM/dd"}
                        mask={"____/__/__"}
                        onChange={(newValue) => {
                            setStartDate(getFormatDate(newValue));
                        }}
                        renderInput={(params) => <TextField style={{width: '150px'}} size="small" {...params} />}
                    />
                </LocalizationProvider>
                ~
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
                    <DatePicker
                        value={endDate}
                        inputFormat={"yyyy/MM/dd"}
                        mask={"____/__/__"}
                        onChange={(newValue) => {
                            setEndDate(getFormatDate(newValue));
                        }}
                        renderInput={(params) => <TextField style={{width: '150px'}} size="small" {...params} />}
                    />
                </LocalizationProvider>

                <Button size="medium" style={{ marginLeft: '10px' }} value="0" onClick={dateButton} variant="outlined" color="primary">어제</Button>
                <Button size="medium" style={{ marginLeft: '10px' }} value="1" onClick={dateButton} variant="outlined" color="primary">오늘</Button>
                <Button size="medium" style={{ marginLeft: '10px' }} value="2" onClick={dateButton} variant="outlined" color="primary">일주일</Button>
                <Button size="medium" style={{ marginLeft: '10px' }} value="3" onClick={dateButton} variant="outlined" color="primary">1개월</Button>

                <FormControl sx={{m: 1, width: 250}}>
                    <Select
                        size="small"
                        defaultValue={'select'}
                        value={selectType}
                        inputProps={{
                            id: 'selectTypeList',
                        }}
                        onChange={(event) => {
                            setSelectType(event.target.value);
                        }}
                        style={{marginLeft: '30px'}}
                    >
                        {selectTypeList.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField id="textSelectType" style={{marginLeft: '30px'}} size="small" variant="outlined"/>
            </Card>
            <br/>
            <Card>
                <CardHeader title="선지급 리스트"/>
                <div className="ag-theme-alpine" style={{height: 520, width: '100%'}}>
                    <AgGridReact
                        rowData={rowData}>
                        <AgGridColumn width={150} headerName="선지급 처리일자" field="PRESTMT_DT" cellRenderer={dateYYYYMMDDGrid}></AgGridColumn>
                        <AgGridColumn width={150} headerName="선지급 처리시간" field="PRESTMT_TM" cellRenderer={dateTimeGrid}></AgGridColumn>
                        <AgGridColumn width={150} headerName="상호" field="CO_NM"></AgGridColumn>
                        <AgGridColumn width={120} headerName="MID" field="MID"></AgGridColumn>
                        <AgGridColumn width={120} headerName="GID" field="GID"></AgGridColumn>
                        <AgGridColumn width={120} headerName="단말기 번호" field="CAT_ID"></AgGridColumn>
                        <AgGridColumn width={120} headerName="VID" field="VID"></AgGridColumn>
                        <AgGridColumn width={120} headerName="L1_VID" field="L1_VID"></AgGridColumn>
                        <AgGridColumn width={120} headerName="총판명" field="VGRP_NM"></AgGridColumn>
                        <AgGridColumn width={120} headerName="L2_VID" field="L2_VID"></AgGridColumn>
                        <AgGridColumn width={120} headerName="대리점명" field="VGRP_NM2"></AgGridColumn>
                        <AgGridColumn width={120} headerName="L3_VID" field="L3_VID"></AgGridColumn>
                        <AgGridColumn width={120} headerName="딜러명" field="VGRP_NM3"></AgGridColumn>
                        <AgGridColumn width={120} headerName="선지급 수수료" field="PRESTMT_FEE"></AgGridColumn>
                        <AgGridColumn width={120} headerName="선지급 금액" field="PRESTMT_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn>
                    </AgGridReact>
                </div>
            </Card>
        </>
    );
};
export default PreStmtMain;