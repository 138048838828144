import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Paysis from './paysis4.png';
import axios from 'axios';
import swal from 'sweetalert';
import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  h2 :{
    fontSize : '0.5rem'
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();

   const loginMms = (value,otp) =>{
    if (!value.uid) {
			swal({
				text: 'UID를 확인해주세요',
				button: {
					text: '확인',
				},
			});
			return false;
		}
		if (!value.password) {
			swal({
				text: '비밀번호를 확인해주세요',
				button: {
					text: '확인',
				},
			});
			return false;
		}
    Swal.showLoading();
    axios
    .post(
       process.env.REACT_APP_API_HOST + "/mms/login",
       {
          uid: value.uid,
          pw: value.password,
          otp : otp,
       },
       {
          headers: {
             'Content-Type': 'application/json',
          },
       }
    )
    .then(function (response) {
      Swal.close();
      if(response.data.res_code =='0010'){
        swal({
          text: response.data.res_msg,
          button: {
            text: '확인',
          },
        }).then(() => {
          navigate('/password', {
            state: {
              uid: response.data.uid
            },
          });
        });
      }else if(response.data.res_code =='0011' || response.data.res_code =='0012' || response.data.res_code =='0013' || response.data.res_code =='0014'){
        Swal.fire({
          text: response.data.res_msg,
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          timer: 600000,
          fontSize : '0.5em',
          showCancelButton: true,
          confirmButtonText: '확인',
          cancelButtonText : '취소',
          showLoaderOnConfirm: true,
          preConfirm: (otp) => {
            loginMms(value,otp);
          }
        })
      }
      else if(response.data.res_code =='9999'){
        swal({
          text: response.data.res_msg,
          button: {
            text: '확인',
          },
        });
        removeCookie('token', { path: '/', secure: false, sameSite: 'lax' });
        removeCookie('id', { path: '/', secure: false, sameSite: 'lax' });
        removeCookie('userid', { path: '/', secure: false, sameSite: 'lax' });
        removeCookie('type', { path: '/', secure: false, sameSite: 'lax' });
        removeCookie('sms', { path: '/', secure: false, sameSite: 'lax' });
        removeCookie('manualpay', { path: '/', secure: false, sameSite: 'lax' });
        removeCookie('banccflg', { path: '/', secure: false, sameSite: 'lax' });
       }else{
        setCookie('token', response.data.user_token, {path: '/',secure: false,sameSite: 'lax',});
        setCookie('id', response.data.id, { path: '/',secure: false,sameSite: 'lax',});
        setCookie('userid', response.data.uid, {path: '/', secure: false, sameSite: 'lax', });
        setCookie('type', response.data.usr_type, {path: '/', secure: false, sameSite: 'lax', });
        setCookie('sms', response.data.sms, { path: '/',secure: false,sameSite: 'lax',});
        setCookie('manualpay', response.data.manualpay, {path: '/', secure: false, sameSite: 'lax', });
        setCookie('banccflg', response.data.ban_cc_flg, {path: '/', secure: false, sameSite: 'lax', });
        navigate('/app/main', { replace: false });
       }
    })
    .catch(function (error) {
      swal({
        text: '미지정 오류',
        button: {
          text: '확인',
        },
      });
      Swal.close();
    });
   }

   //쿠키 초기화
   useEffect(() => {
    removeCookie('token', { path: '/', secure: false, sameSite: 'lax' });
    removeCookie('id', { path: '/', secure: false, sameSite: 'lax' });
    removeCookie('userid', { path: '/', secure: false, sameSite: 'lax' });
    removeCookie('type', { path: '/', secure: false, sameSite: 'lax' });
    removeCookie('sms', { path: '/', secure: false, sameSite: 'lax' });
    removeCookie('manualpay', { path: '/', secure: false, sameSite: 'lax' });
    removeCookie('banccflg', { path: '/', secure: false, sameSite: 'lax' });
   },[])

  return (
   
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Typography variant="h1" component="h1" gutterBottom align="left" color="primary">
            주식회사 페이시스 가맹점관리자
            
            <p align='left' style={{marginTop:'30PX'}}><img src={Paysis}></img></p>
          </Typography>
        </Container>
        <Box p={3} />
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              uid: '',
              password: ''
            }}
            onSubmit={(value) => {
              loginMms(value,"X");
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    로그인
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on the internal platform
                  </Typography>
                </Box>
                <Box
                  mt={3}
                  mb={1}
                >
                </Box>
                <TextField
                  fullWidth
                  label="UID"
                  margin="normal"
                  name="uid"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="uid"
                  value={values.uid}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    로그인
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        
        </Container>
      </Box>
  );
};

export default LoginView;
