import { numberWithCommas } from "../numberWithCommas";

export const preStmtReportExcel = (data,usrType) =>{

  var headers;
  var headerName;
  var newRowData;
  const xlsx = require( "xlsx" );

  if(usrType == 1){
    headers = [
        "PRESTMT_DT"
        ,"PRESTMT_TM"
        ,'CO_NM'
        ,"MID"
        ,"GID"
        ,"CAT_ID"
        ,"VID"
        ,"L1_VID"
        ,"VGRP_NM"
        ,"L2_VID"
        ,"VGRP_NM2"
        ,"L3_VID"
        ,"VGRP_NM3"
        ,"PRESTMT_FEE"
        ,"PRESTMT_AMT"
    ];

    headerName = {
      'PRESTMT_DT': '선지급 처리일자',
      'PRESTMT_TM': '선지급 처리시간',
      'CO_NM': '상호',
      'MID': ' MID',
      'GID' : 'GID',
      'CAT_ID': '단말기 번호',
      'VID': 'VID',
      'L1_VID': 'L1_VID',
      'VGRP_NM': '총판명',
      'L2_VID': 'L2_VID',
      'VGRP_NM2': '대리점명',
      'L3_VID': 'L3_VID',
      'VGRP_NM3': '딜러명',
      'PRESTMT_FEE': '선지급 수수료',
      'PRESTMT_AMT': '선지급 금액'
    }

  newRowData = data.map(rec => {
    return {
      'PRESTMT_DT': rec.PRESTMT_DT,
      'PRESTMT_TM': rec.PRESTMT_TM,
      'CO_NM': rec.CO_NM,
      'MID': rec.MID,
      'GID' : rec.GID,
      'CAT_ID': rec.CAT_ID,
      'VID': rec.VID,
      'L1_VID': rec.L1_VID,
      'VGRP_NM': rec.VGRP_NM,
      'L2_VID': rec.L2_VID,
      'VGRP_NM2': rec.VGRP_NM2,
      'L3_VID': rec.L3_VID,
      'VGRP_NM3': rec.VGRP_NM3,
      'PRESTMT_FEE': rec.PRESTMT_FEE,
      'PRESTMT_AMT': rec.PRESTMT_AMT

    }
  })
  }
    newRowData.unshift(headerName);

    const book = xlsx.utils.book_new();
    const bankReportList = xlsx.utils.json_to_sheet( newRowData

, { header :headers , skipHeader : true } );

xlsx.utils.book_append_sheet( book,bankReportList);

xlsx.writeFile( book, '선지급 조회.xlsx');
}
