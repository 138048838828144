import React, { useState,useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@material-ui/core';
import axios from 'axios';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { useCookies } from 'react-cookie';
import { Navigate ,useNavigate } from 'react-router-dom';

export default (props) => {

    const [open, setOpen] = React.useState(false);
    const [cookies, setCookie, removeCookie] = useCookies();
    const [midInfoData,setMidInfoData] = useState({});
    const [feeMap, setFeeMap] = useState({});
    const [smcData, setSmcData] = useState({});
    const navigate = useNavigate();

    const handleClickOpen = () => {
        midInfoDetail();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const midInfoDetail = () =>{
        Swal.showLoading();
        axios
            .post(
                process.env.REACT_APP_API_HOST + "/mms/selectMidInfoDetail",
                {
                    token : cookies.token,
                    userId : cookies.userid,
                    usrTypeId : cookies.id,
                    usrType:cookies.type,
                    mid : cellValue
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                }
            )
            .then(function (response) {
                if (response.data.res_code != '0000') {
                    swal({
                        text: response.data.res_msg,
                        button: {
                            text: '확인',
                        },
                    });
                    if (response.data.res_code == '1004') {
                        swal({
                            text: response.data.res_msg,
                            button: {
                                text: '확인',
                            },
                        });
                        navigate('/login', { replace: false });
                    }
                    Swal.close();
                } else {
                    setMidInfoData(response.data.data);
                    setFeeMap(response.data.feedata);
                    setSmcData(response.data.smcData);
                    setOpen(true);
                    Swal.close();
                }
            }).catch(function (error) {
            Swal.close();
            swal({
                text: '미지정 오류',
                button: {
                    text: '확인',
                },
            });
        });
    }

    console.log("테스트입니다.");

    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

    useEffect(() => {
        if (Array.isArray(feeMap)) {
            const newFeeMap = feeMap.reduce((acc, item) => {
                acc[item.SM_MBS_CD] = item.FEE;
                return acc;
            }, {});
            setFeeMap(newFeeMap);
        }
    }, [feeMap]);

    useEffect(() => {
        if (Array.isArray(smcData)) {
            const newSmc = smcData.reduce((acc, item) => {
                acc[item.SM_MBS_CD] = item.SM_MBS_CD;
                return acc;
            }, {});
            setSmcData(newSmc);
        }
    }, [smcData]);



    return (
        <span>
      <Button onClick={handleClickOpen} >상세보기</Button>
      <Dialog
          open={open}
          keepMounted
          maxWidth={false}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"가맹점 정보"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <Table size='small' style={{marginBottom:'60px'}}>
          <TableBody>
                <TableRow>
                    <TableCell>MID</TableCell>
                    <TableCell> {midInfoData.MID}</TableCell>
                    <TableCell>GID</TableCell>
                    <TableCell>{midInfoData.GID}</TableCell>

                    <TableCell>단말기번호</TableCell>
                    <TableCell>{midInfoData.CAT_ID}</TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell>사업자번호</TableCell>
                    <TableCell>{midInfoData.CO_NO}</TableCell>
                    <TableCell>상호명</TableCell>
                    <TableCell>{midInfoData.CO_NM}</TableCell>
                     <TableCell>대표자명</TableCell>
                    <TableCell>{midInfoData.REP_NM}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>대표 TEL</TableCell>
                    <TableCell>{midInfoData.TEL_NO}</TableCell>
                    <TableCell>대표 이메일</TableCell>
                    <TableCell>{midInfoData.EMAIL}</TableCell>
                    <TableCell>간판명</TableCell>
                    <TableCell>{midInfoData.SIGN_NM}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>업 종</TableCell>
                    <TableCell>{midInfoData.BS_KIND}</TableCell>
                    <TableCell>업 태</TableCell>
                    <TableCell>{midInfoData.GD_KIND}</TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell>수기결제</TableCell>
                  <TableCell>{midInfoData.MANUAL_PAY_FLG}</TableCell>
                  <TableCell>SMS결제</TableCell>
                  <TableCell>{midInfoData.SMS_PAY_FLG}</TableCell>
                  <TableCell>인증결제</TableCell>
                  <TableCell>{midInfoData.MER_AUTH_CD}</TableCell>
                  </TableRow>
              </TableBody>
                </Table>

                <Table size='small' style={{marginBottom:'40px'}}>
                <TableBody>
                    <TableRow>
                    <TableCell>사업장주소</TableCell>
                    <TableCell>{midInfoData.ADDR}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>Mkey</TableCell>
                    <TableCell>{midInfoData.MKEY}</TableCell>
                  </TableRow>
              </TableBody>
                </Table>

                <Table size='small' style={{marginBottom:'20px'}}>
                <TableBody>
                <TableRow>
                  <TableCell>은행</TableCell>
                  <TableCell>{midInfoData.BANK_NM}</TableCell>
                  <TableCell>예금주</TableCell>
                  <TableCell>{midInfoData.ACNT_NM}</TableCell>
                  <TableCell>계좌번호</TableCell>
                  <TableCell>{midInfoData.ACNT_NO}</TableCell>
                  <TableCell>입금 주기</TableCell>
                  <TableCell>{midInfoData.STMT_CYCLE_CD}일</TableCell>
                </TableRow>
              </TableBody>
                </Table>
                <Table size='small' style={{marginBottom:'20px'}}>
                <TableBody>
                <TableRow>
                  <TableCell>수수료</TableCell>
                </TableRow>
               <TableRow>
              <TableCell style={{ color: smcData?.SM_MBS_CD === "00" ? 'red' : 'black' }}>일반</TableCell>
              <TableCell>
                <span style={{ color: smcData?.SM_MBS_CD === "00" ? 'red' : 'black' }}>
                  {feeMap['00'] || null}
                </span>
              </TableCell>

              <TableCell style={{ color: smcData?.SM_MBS_CD === "B3" ? 'red' : 'black' }}>중소3</TableCell>
              <TableCell>
                <span style={{ color: smcData?.SM_MBS_CD === "B3" ? 'red' : 'black' }}>
                  {feeMap['B3'] || null}
                </span>
              </TableCell>

              <TableCell style={{ color: smcData?.SM_MBS_CD === "B2" ? 'red' : 'black' }}>중소2</TableCell>
              <TableCell>
                <span style={{ color: smcData?.SM_MBS_CD === "B2" ? 'red' : 'black' }}>
                  {feeMap['B2'] || null}
                </span>
              </TableCell>

              <TableCell style={{ color: smcData?.SM_MBS_CD === "B1" ? 'red' : 'black' }}>중소1</TableCell>
              <TableCell>
                <span style={{ color: smcData?.SM_MBS_CD === "B1" ? 'red' : 'black' }}>
                  {feeMap['B1'] || null}
                </span>
              </TableCell>

              <TableCell style={{ color: smcData?.SM_MBS_CD === "A1" ? 'red' : 'black' }}>영세</TableCell>
              <TableCell>
                <span style={{ color: smcData?.SM_MBS_CD === "A1" ? 'red' : 'black' }}>
                  {feeMap['A1'] || null}
                </span>
              </TableCell>
            </TableRow>
              </TableBody>
                </Table>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
      
          <Button onClick={handleClose}>닫기</Button>
        </DialogActions>
      </Dialog>
    </span>
    );
};